var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "survey-answer-card",
      class: _vm.answer.read ? "" : "unread",
      on: { click: _vm.data.click },
    },
    [
      _c("div", { staticClass: "survey-answer-card__main" }, [
        _vm.data.global
          ? _c("div", { staticClass: "fw500 flex bet a-center" }, [
              _c("div", {
                domProps: { textContent: _vm._s(_vm.answer.survey.title) },
              }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "survey-answer-card__main_main" }, [
          _vm.data.global
            ? _c("div", { staticClass: "survey-answer-card__main_name" }, [
                _c("div", {
                  staticClass: "grey",
                  domProps: { textContent: _vm._s(_vm.$t("Branch")) },
                }),
                _c("div", {
                  domProps: { textContent: _vm._s(_vm.branchesName) },
                }),
              ])
            : _vm._e(),
          _c("div", { staticClass: "survey-answer-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Score")) },
            }),
            _c("div", {
              domProps: {
                textContent: _vm._s(
                  Math.round(_vm.answer.feedback.score * 100) / 100
                ),
              },
            }),
          ]),
          _c("div", { staticClass: "survey-answer-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Client")) },
            }),
            _c("div", { domProps: { textContent: _vm._s(_vm.answer.name) } }),
            _c("div", { domProps: { textContent: _vm._s(_vm.answer.phone) } }),
          ]),
          _c(
            "div",
            {
              staticClass: "survey-answer-card__main_name status",
              class: _vm.className,
            },
            [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Status")) },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t(
                      ["Waiting", "Sent", "_Opened", "Answered"][
                        _vm.answer.status
                      ]
                    )
                  ),
                },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.filters.clockTime(_vm.time) +
                      " " +
                      _vm.filters.date(_vm.time, "DD/MM/YYYY")
                  ),
                },
              }),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }